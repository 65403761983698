<template>
  <div>
    <v-row class="mb-3">
      <v-col cols="12" md="3">
        <router-link :to="`/message-center/messages/new`">
          <v-btn class="orange-bg white--text">Send New Message</v-btn>
        </router-link>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="records"
      item-key="id"
      :loading="isLoading"
      :server-items-length="totalRecords"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template #[`header.direction`]="{ header }">
        <v-icon color="green" small>
          {{ mdiEmailReceive }}
        </v-icon>
        {{ header.text }}
        <v-icon color="black" small>
          {{ mdiEmailSend }}
        </v-icon>
      </template>
      <template #[`item.created`]="{ item }">
        {{ formatDateUs(item.created) }}
      </template>
      <template #[`item.subject`]="{ item }">
        <router-link :to="`/message-center/messages/${item.id}/view`">
          <span :style="item.isNew ? 'color: green; font-weight: bold;' : ''">
            {{ item.subject }}
          </span>
        </router-link>
      </template>
      <template #[`item.fromOrTo`]="{ item }">
        {{ item.fromOrTo }}
      </template>
      <template #[`item.direction`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              :color="item.direction === 'In' ? 'green' : 'black'"
            >
              {{ item.direction === "In" ? mdiEmailReceive : mdiEmailSend }}
            </v-icon>
          </template>
          <span>{{ item.direction === "In" ? "Received" : "Sent" }}</span>
        </v-tooltip>
      </template>
      <template #[`item.isActionRequired`]="{ item }">
        <span>{{ item.isActionRequired ? "Yes" : "No" }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn class="ma-2" text icon @click.stop="item.dialog = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-dialog v-model="item.dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">
              Delete Message
            </v-card-title>
            <v-card-text
              >Are you sure you want to delete message:
              <p>{{ item.subject }}</p></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="item.dialog = false">
                Cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="deleteMessage(item)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import { GET_PAGED, POST_DATA, API_USERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

import { mdiEmailReceiveOutline, mdiEmailSendOutline } from "@mdi/js";
// import moment from "moment";
import dateFormatter from "@/core/helpers/dateFormatter";
import { GET_QUERY, DELETE, API_MESSAGES } from "@/core/store/crud.module";
import Mgr from "@/core/services/security.service";

export default {
  data: () => ({
    mgr: new Mgr(),
    isLoading: false,
    headers: [
      {
        text: "Date",
        align: "start",
        value: "created",
        class: "orange-bg"
      },
      { text: "In/Out", value: "direction", class: "orange-bg" },
      { text: "From / Sent To", value: "fromOrTo", class: "orange-bg" },
      { text: "Reference", value: "subject", class: "orange-bg" },
      { text: "Action Req.", value: "isActionRequired", class: "orange-bg" },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    records: [],
    totalRecords: null,
    dialog: false,
    tab: null,
    mdiEmailReceive: mdiEmailReceiveOutline,
    mdiEmailSend: mdiEmailSendOutline,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["date"],
      sortDesc: [true]
    }
  }),
  watch: {
    // userQuery: async function() {
    //   if (this.userQuery && this.userQuery.length > 0) {
    //     await this.getUsers();
    //   } else {
    //     this.users = [];
    //   }
    // },
    "options.sortBy": async function() {
      await this.getMessages();
      // if (this.userQuery && this.userQuery.length > 0) {
      //   await this.getUsers();
      // } else {
      //   this.users = [];
      // }
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Message Center" }]);
    await this.getMessages();
    this.isLoading = false;
  },
  methods: {
    deleteMessage(item) {
      let self = this;
      if (!item) return;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_MESSAGES}`,
          id: item.id
        })
        .then(function() {
          item.dialog = false;

          self.getMessages();
        });
    },
    getDirection(item) {
      // let self = this;
      this.mgr.getUser().then(userInfo => {
        item.direction = item.sender.id === userInfo.profile.sub ? "In" : "Out";
        // item.userInfo = userInfo;
      });
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY"); 
      return dateFormatter.formatDateUs(date);
    },
    async getMessages() {
      this.loading = true;

      this.isLoading = true;
      let self = this;
      this.mgr.getUser().then(userInfo => {
        // item.userInfo = userInfo;

        this.$store
          .dispatch(GET_QUERY, {
            listName: `${API_MESSAGES}`
          })
          .then(response => {
            if (response.status >= 200 || response.status <= 204) {
              const { sortBy, sortDesc, page, itemsPerPage } = self.options;
              let items = response.data.$values;

              items.forEach(item => {
                item.dialog = false;
                if (item.sender.id === userInfo.profile.sub) {
                  item.direction = "Out";
                  item.fromOrTo = item.recipients.$values
                    .map(el => el.fullName)
                    .join("; ");
                } else {
                  item.direction = "In";
                  item.fromOrTo = item.sender.fullName;
                }
              });

              const total = items.length;

              if (sortBy.length === 1 && sortDesc.length === 1) {
                items = items.sort((a, b) => {
                  const sortA = a[sortBy[0]];
                  const sortB = b[sortBy[0]];

                  if (sortDesc[0]) {
                    if (sortA < sortB) return 1;
                    if (sortA > sortB) return -1;
                    return 0;
                  } else {
                    if (sortA < sortB) return -1;
                    if (sortA > sortB) return 1;
                    return 0;
                  }
                });
              }

              if (itemsPerPage > 0) {
                items = items.slice(
                  (page - 1) * itemsPerPage,
                  page * itemsPerPage
                );
              }
              self.records = items;
              // self.records.forEach(element => {
              //   self.getDirection(element);
              // });
              self.totalRecords = total;
            } else {
              self.$snackbar.showMessage({
                content: response.data || "Something went wrong!",
                color: "red"
              });
            }

            self.loading = false;
            self.isLoading = false;
          });
      });
    }
  },
  computed: {
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
